<template>
  <div>
    <Loader v-if="globalStore.isLoading" />
    <main class="main-content mt-0">
      <slot />
    </main>
  </div>
</template>

<script setup>
import Loader from "~/components/Layout/Loader.vue";

const { globalStore } = useNuxtApp();
const route = useRoute();

useHead({
  titleTemplate: (titleChunk) => {
    return `${titleChunk} - ${route.meta.title}`;
  },
});
</script>
